<template>
  <div
    class="bg-background-400 dark:bg-background-100 text-background-100 dark:text-background-500 min-h-screen min-w-full"
    :class="{
      'w-fit': !['/workorders/shop-scheduler'].includes($route.path),
    }"
  >
    <Sidebar />
    <div
      class="md:ml-32 md:px-12"
      :class="{
        'py-12': !['/calendar', '/workorders/shop-scheduler'].includes(
          $route.path
        ),
      }"
    >
      <slot />
    </div>
    <Toast />
  </div>
</template>
